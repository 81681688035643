import React, { useContext, useState } from "react"
import Papa from "papaparse"
import { postId } from "../../myApiCenter"
import toast from "react-hot-toast"
import { AuthContext } from "../../context/Can"
// Endpoint for fetching products

const CsvParser = () => {
   const [csvData, setCsvData] = useState([])
   const [error, setError] = useState(null)
   const [userData, userDataSetter] = useContext(AuthContext)

   const handleFileUpload = (e) => {
      console.log(csvData)
      const file = e.target.files[0]
      Papa.parse(file, {
         complete: (result) => {
            // Result.data is an array containing the parsed CSV data
            setCsvData(result.data)
         },
         error: (err) => {
            setError(err.message)
         }
      })
   }
   console.log(userData)
   const submitHandler = async (orderId, trackingId, company) => {
      if (userData?.isApprovedFromAdmin) {
         try {
            // const j = await axios.get(`${shopifyStoreUrl}/admin/api/2023-10/orders.json`, { headers })
            // console.log(j)

            const j = await postId(orderId, trackingId, company)
            console.log(j)
         } catch (error) {
            console.log(error)
         }
      } else {
         toast.error("you are not actived,contact admin")
      }
   }
   const updateAllHandler = async () => {
      if (userData?.isApprovedFromAdmin) {
         if (csvData.length > 1) {
            // csvData.map(async (e) => {
            //    console.log(e)
            //    try {
            //       const j = await postId(e[0], e[1], e[2])
            //       console.log(j)
            //    } catch (error) {
            //       console.log(error)
            //    }
            // })
            for (let i in csvData) {
               if (i > 0) {
                  console.log(csvData[i])
                  try {
                     const j = await postId(csvData[i][0], csvData[i][1], csvData[i][2])
                     console.log(j)
                  } catch (error) {
                     console.log(error)
                  }
               }
            }
            // for (let i in csvData) {
            //    if (i > 0) {
            //       console.log(csvData[i])
            //    }
            // }
         } else {
            toast.error("upload a file have data")
         }
      } else {
         toast.error("you are not actived,contact admin")
      }
   }
   // console.log(csvData)
   return (
      <div style={{ margin: "80px" }}>
         <input type="file" onChange={handleFileUpload} />
         <button type="button" onClick={updateAllHandler}>
            {" "}
            UPDATE ALL in
         </button>
         {error && <p style={{ color: "red" }}>{error}</p>}
         <table>
            <thead>
               <tr>{csvData[0] && csvData[0].map((header, index) => <th key={index}>{header}</th>)}</tr>
            </thead>
            <tbody>
               {csvData.slice(1).map((row, rowIndex) => (
                  <tr key={rowIndex}>
                     {row.map((cell, cellIndex) => (
                        <td key={cellIndex}>{cell}</td>
                     ))}
                     {row[0] && (
                        <td>
                           <button
                              onClick={() => {
                                 submitHandler(row[0], row[1], row[2])
                              }}
                           >
                              update order
                           </button>
                        </td>
                     )}
                  </tr>
               ))}
            </tbody>
         </table>
      </div>
   )
}

export default CsvParser
