import { Input } from "reactstrap"
import { isoToLocale } from "../utils"

export const userColumnList = [
   {
      name: "ID",
      sortable: true,
      minwidth: "150px",
      selector: (row) => row._id
   },
   {
      name: "Name",
      sortable: true,
      minwidth: "100px",
      selector: (row) => row.name
   },
   {
      name: "Email",
      sortable: true,
      minwidth: "100px",
      selector: (row) => row.email
   },
   {
      name: "Contact No",
      sortable: true,
      minwidth: "100px",
      selector: (row) => row.mobileNo
   },
   {
      name: "Shop URL",
      sortable: true,
      minwidth: "100px",
      selector: (row) => row.shopifyStoreUrl
   },
   {
      name: "Shop Id",
      sortable: true,
      minwidth: "100px",
      selector: (row) => row.shopId
   },
   {
      name: "ACTIVE",
      sortable: true,
      minwidth: "150px",
      selector: (row) => (
         <div className="form-switch form-check-success">
            <Input type="switch" id="" onChange={(e) => console.log(e.target.checked)} name="success" disabled checked={row.isApprovedFromAdmin} />
         </div>
      )
   }
]
