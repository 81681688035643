import MainNav from "../MainNav/MainNav"

const About = () => {
   return (
      <>
         <MainNav />
         <div className="">
            <h1>About</h1>
         </div>
      </>
   )
}

export default About
