// import { instance, instance2 } from "./utils"
import { toast } from "react-hot-toast"
import axios from "axios"
/*********************** ** */
export function getCookieByName(cookiename) {
   // Get name followed by anything except a semicolon
   const cookiestring = RegExp(`${cookiename}=[^;]+`).exec(document.cookie)
   // Return everything after the equal sign, or an empty string if the cookie name not found
   return decodeURIComponent(!!cookiestring ? cookiestring.toString().replace(/^[^=]+./, "") : "")
}
export const deleteCookie = (name) => {
   document.cookie = name + "="
}

// To delete a specific cookie by name, call the function with the cookie name

export const instance = () => {
   return axios.create({
      baseURL: process.env.REACT_APP_BASE_API_URL,
      headers: { Authorization: `Bearer ${getCookieByName("token1")}` },
      mode: "no cors"
   })
}

export const instance3 = () => {
   return axios.create({
      baseURL: `http://localhost:8080`,
      //   headers: { Authorization: `Bearer ${getCookieByName("token")}` }
      headers: { Authorization: `Bearer ` }
   })
}

export const postRequestToken = async (data) => {
   const newInstance = instance3()
   try {
      const j = await newInstance.post("/token", data)
      console.log(j)
      toast.success(j.data?.status)
      // console.log(j)
      return j
   } catch (error) {
      toast.error(error.response?.data?.status)
      console.log(error)
      //   handleApiError(error)
   }
}

export const instance2 = axios.create({
   baseURL: "http://localhost:3100", // Set your API base URL
   timeout: 5000 // Set a timeout for requests (adjust as needed)
})

export const postLogin = async (data) => {
   const newInstance = instance()
   try {
      const j = await newInstance.post("auth/login", data)
      // toast.success("color added successfully")
      console.log(j.data)
      if (j) {
         return j.data
      }
   } catch (error) {
      console.log(error)
      toast.error(`${error.response?.data?.message}`)
   }
}
export const postSignUp = async (data) => {
   const newInstance = instance()
   try {
      const j = await newInstance.post("users/sign-up", data)
      toast.success(j.data.message)
      if (j) {
         return j.data
      }
   } catch (error) {
      console.log(error)
      toast.error(`${error.response?.data?.message}`)
   }
}
/*********************** ** */

/*********************** ** */

export const postId = async (orderId, trackingId, company) => {
   console.log(orderId)
   console.log(trackingId)
   const newInstance = instance()

   if (orderId.length > 0 && trackingId.length > 0 && company.length > 0) {
      try {
         toast.loading("updating")
         const j = await newInstance.post("users/update-tracking", { trackingId, orderId, company })

         console.log(JSON.stringify(j.data))
         toast.dismiss()
         toast.success("updated")
      } catch (error) {
         toast.dismiss()
         toast.error(error.response?.data?.message)
      } finally {
         //   toast.dismiss()
      }
   }
}

/*********************** ** */

export const postAddUser = async (data) => {
   const newInstance = instance()
   try {
      const j = await newInstance.post("/admin/add-user", data)
      toast.success(j.data.message)
      // console.log(j)
      return j
   } catch (error) {
      console.log(error)
      toast.error(`${error.response.data.message}`)
   }
}

export const putUser = async (id, data) => {
   const newInstance = instance()
   try {
      const j = await newInstance.patch(`admin/edit-user/${id}`, data)
      toast.success("user updated successfully")
      console.log(j)
      return j
   } catch (error) {
      console.log(error)
      toast.error(`${error.response.data.message}`)
   }
}

export const getUserById = async (id) => {
   const newInstance = instance()
   try {
      const j = await newInstance.get(`/admin/get-user/${id}`)
      // toast.success("type added successfully")
      return j
   } catch (error) {
      console.log(error)
      toast.error(`${error.response.data.message}`)
   }
}

export const getAllUser = async () => {
   const newInstance = instance()
   try {
      const j = await newInstance.get(`/admin/get-all-users`)
      // toast.success("type added successfully")
      return j
   } catch (error) {
      console.log(error)
      toast.error(`${error.response.data.message}`)
   }
}

export const resetPasswordForUser = async (id, data) => {
   const newInstance = instance()
   try {
      const j = await newInstance.patch(`admin/reset-password/${id}`, data)
      toast.success(j.data)
      // console.log(j)
      return j
   } catch (error) {
      console.log(error)
      toast.error(`${error.response.data.message}`)
   }
}
/*********************** ** */
/*********************** ** */
