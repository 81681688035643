import { Card, Col, Input, Label, ListGroup, Button, Row, ListGroupItem, Form, Spinner } from "reactstrap"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useState, useContext, useEffect } from "react"
import { toast } from "react-hot-toast"
import Select from "react-select"
import { InputOption, dummyItemOptions, subjectOptions } from "../../utils"
import { ArrowLeft } from "react-feather"
import Flatpickr from "react-flatpickr"
import { getUserById, postAddUser, putUser } from "../../myApiCenter"
import MainNav from "../MainNav/MainNav"
import { AuthContext } from "../../context/Can"

const AddUser = () => {
   const { id } = useParams()
   const [userData, userDataSetter] = useContext(AuthContext)

   const navigate = useNavigate()
   const [loading, setloading] = useState(false)
   const [data, setdata] = useState({
      name: "",
      password: "",
      email: "",
      mobileNo: "",
      userRole: "user",
      membership: "normal",
      isApprovedFromAdmin: false,
      shopId: "",
      shopifyStoreUrl: ""
   })
   console.log(userData)
   const getData = async () => {
      try {
         const j = await getUserById(id)
         setdata({ ...j.data?.user })
      } catch (error) {
         console.log(error)
      }
   }

   // console.log(data)
   const submitHandler = async (e) => {
      e.preventDefault()
      console.log(data)
      const updatedData = { ...data }
      console.log(data)
      delete data.password
      if (data) {
         try {
            setloading(true)
            let j
            if (id) {
               j = await putUser(id, updatedData)
            } else {
               j = await postAddUser(updatedData)
            }
            j && navigate("/users")
         } finally {
            setloading(false)
         }
      } else {
         toast.error("please select generic service")
      }
   }
   useEffect(() => {
      {
         id && getData()
      }
      return () => {}
   }, [])
   // console.log(data)
   // console.log(stockOption)
   return (
      <>
         <MainNav />

         <Card className="my-form-wrap">
            <div className="d-flex align-items-center p-1 ">
               <button style={{ border: "transparent" }} onClick={() => navigate(-1)}>
                  {/* < style={{ color: "gray" }} /> */}
                  <ArrowLeft />
               </button>
               <h1 className="ms-2 datatable-title-head">{id ? "Edit User" : "Add User"}</h1>
            </div>
            <div className="w-100">
               <Col>
                  <Form className="d-flex justify-content-center gap-2 flex-column w-100" onSubmit={submitHandler}>
                     <Row>
                        <Col className="ms-3 mt-2 me-auto xs">
                           <p className="h4 me-auto " style={{ textAlign: "start" }}>
                              Name <span style={{ color: "red" }}>*</span>
                           </p>
                           <Input
                              value={data.name}
                              required
                              onChange={(e) => setdata({ ...data, name: e.target.value })}
                              type="text"
                              placeholder="Enter contact person name"
                              className="w-100"
                           />
                        </Col>
                     </Row>
                     <Row>
                        <Col className="ms-3 mt-2">
                           <p className="h4" style={{ textAlign: "start" }}>
                              EMAIL <span style={{ color: "red" }}>*</span>
                           </p>
                           <Input
                              value={data.email}
                              required
                              onChange={(e) => setdata({ ...data, email: e.target.value })}
                              type="email"
                              placeholder="Enter email"
                           />
                        </Col>
                     </Row>
                     <Row>
                        {!id && (
                           <Col className="ms-3 mt-2">
                              <p className="h4" style={{ textAlign: "start" }}>
                                 Password <span style={{ color: "red" }}>*</span>
                              </p>
                              <Input
                                 value={data.password}
                                 required
                                 onChange={(e) => setdata({ ...data, password: e.target.value })}
                                 type="password"
                                 placeholder="Enter password"
                              />
                           </Col>
                        )}

                        <Col className="ms-3 mt-2">
                           <p className="h4" style={{ textAlign: "start" }}>
                              Mobile No <span style={{ color: "red" }}>*</span>
                           </p>
                           <Input
                              value={data.mobileNo}
                              required
                              onChange={(e) => setdata({ ...data, mobileNo: e.target.value })}
                              type="number"
                              placeholder="ex. Your contact no"
                           />
                        </Col>
                     </Row>
                     <Row>
                        <Col className="ms-3 mt-2">
                           <p className="h4" style={{ textAlign: "start" }}>
                              Shop Url <span style={{ color: "red" }}>*</span>
                           </p>
                           <Input
                              value={data.shopifyStoreUrl}
                              required
                              onChange={(e) => setdata({ ...data, shopifyStoreUrl: e.target.value })}
                              type="url"
                              placeholder="Enter shop Url"
                           />
                        </Col>
                     </Row>
                     <Row>
                        <Col className="ms-3 mt-2">
                           <p className="h4" style={{ textAlign: "start" }}>
                              Shop Id <span style={{ color: "red" }}>*</span>
                           </p>
                           <Input
                              value={data.shopId}
                              required
                              onChange={(e) => setdata({ ...data, shopId: e.target.value })}
                              type="text"
                              placeholder="Enter shop Url"
                           />
                        </Col>
                     </Row>
                     {userData?.userRole === "admin" && (
                        <Row>
                           <Col className="ms-3 mt-4 mb-2 d-flex gap-5 align-items-center">
                              <p className="h4 me-2 " style={{ textAlign: "start" }}>
                                 Active
                              </p>
                              <div className=" form-switch form-check-success">
                                 <Input
                                    type="switch"
                                    id=""
                                    onChange={(e) => setdata({ ...data, isApprovedFromAdmin: e.target.checked })}
                                    name="success"
                                    checked={data.isApprovedFromAdmin}
                                 />
                              </div>
                           </Col>
                        </Row>
                     )}
                     <Button className="w-25 mt-2 ms-3 my-btn" disabled={loading}>
                        Save {loading && <Spinner size="sm" />}
                     </Button>
                  </Form>
               </Col>
            </div>
         </Card>
      </>
   )
}
export default AddUser
