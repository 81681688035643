import MainNav from "../MainNav/MainNav"

const Home = () => {
   return (
      <div className="RootLayout">
         <MainNav />
         <h1>Home</h1>
      </div>
   )
}

export default Home
