import { Link, NavLink, useNavigate } from "react-router-dom"
// import logo from "../assets/logo.PNG"
import { FaEnvelope, FaGift, FaGlobe, FaLock, FaModx, FaPhone, FaUser } from "react-icons/fa"
import { useState } from "react"
import { Spinner } from "reactstrap"
import { postSignUp } from "../../myApiCenter"
import MainNav from "../MainNav/MainNav"
// import { facultyLogin, studentLogin } from "../myApiCenter"
// import { AuthContext } from "../context/Can"

const SignUp = () => {
   const navigate = useNavigate()
   const [data, setdata] = useState({
      name: "",
      password: "",
      email: "",
      mobileNo: "",
      shopId: "",
      shopifyStoreUrl: ""
   })

   const [loading, setloading] = useState(false)

   const inputHandler = (e) => {
      setdata({ ...data, [e.target.name]: e.target.value })
   }
   const submitHandler = async (e) => {
      e.preventDefault()
      setloading(true)
      console.log(data)
      try {
         //  if (props.title === "students") {
         const j = await postSignUp(data)
         console.log(j)
         if (j) {
            navigate("/login")
         }
      } catch (error) {
         console.log(error)
      } finally {
         setloading(false)
         setdata({
            name: "",
            password: "",
            email: "",
            mobileNo: "",
            shopId: "",
            shopifyStoreUrl: ""
         })
      }
   }

   return (
      <>
         <MainNav />
         <div className="w-75   login-box">
            <div className="w-75 me-auto d-flex text-primary" style={{ padding: "10px 23px" }}>
               <NavLink className="" to={"/"}>
                  {/* <img className="m-2" src={logo} alt="logo" /> */}
               </NavLink>
            </div>
            <div className="text-primary d-flex flex-column align-items-center ">
               <h1>Sign Up to SHOPIFY AUTO TRACKING UPDATER </h1>
               <form onSubmit={submitHandler} className="d-flex w-75 flex-column ">
                  <div className="mb-4 w-100 login-input">
                     <i>
                        <FaUser />
                     </i>
                     <input
                        required
                        //  value={data.email}
                        onChange={inputHandler}
                        name="name"
                        type="text"
                        placeholder="Enter your name"
                     />
                  </div>
                  <div className="mb-4  login-input">
                     <i>
                        <FaEnvelope />
                     </i>
                     <input required value={data.email} onChange={inputHandler} name="email" type="email" placeholder="Enter your Email" />
                  </div>
                  <div className="mb-4  login-input">
                     <i style={{ rotate: "90deg" }}>
                        <FaPhone />
                     </i>
                     <input
                        required
                        value={data.mobileNo}
                        onChange={inputHandler}
                        name="mobileNo"
                        type="number"
                        placeholder="Enter your Mobile Number"
                     />
                  </div>

                  <div className="mb-4 login-input">
                     <i>
                        <FaLock />
                     </i>
                     <input
                        required
                        minLength="6"
                        //  value={data.password}
                        name="password"
                        onChange={inputHandler}
                        type="password"
                        placeholder="Enter your Password"
                     />
                  </div>
                  <div className="mb-4 login-input">
                     <i>
                        <FaGlobe />
                     </i>
                     <input
                        // required
                        minLength="6"
                        value={data.shopifyStoreUrl}
                        name="shopifyStoreUrl"
                        onChange={inputHandler}
                        type="url"
                        placeholder="Enter your Shop Url"
                     />
                  </div>
                  <div className="mb-4 login-input">
                     <i>
                        <FaGift />
                     </i>
                     <input
                        // required
                        minLength="6"
                        value={data.shopId}
                        name="shopId"
                        onChange={inputHandler}
                        type="text"
                        placeholder="Enter your Shop Id"
                     />
                  </div>
                  <div className="login-btn-grp">
                     {" "}
                     <button type="submit" disabled={loading} className="mt-3">
                        Sign Up {loading && <Spinner size="sm" color="light" />}
                     </button>
                     <button className="mt-3" onClick={() => navigate("/home")}>
                        Back
                     </button>
                  </div>
               </form>
            </div>
         </div>
      </>
   )
}
export default SignUp
